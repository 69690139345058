<template>
  <v-sheet class="px-sm-12" width="100%">
    <slot />
  </v-sheet>
</template>

<script>
export default {
  name: 'PageTabs',
};
</script>
