export const STStatus = {
  CREATING: 'creating',
  NEW: 'new',
  INVOICING: 'invoicing',
  WAITING_FOR_WIRE_PAYMENT: 'waiting for wire payment',
  WAITING_FOR_PAYMENT: 'waiting for payment',
  CONFIRMED: 'confirmed',
  ACTIVE: 'active',
  IN_WORK: 'in_work',
  PACKED: 'packed',
  DELIVERY: 'delivery',
  PROCESSING_REQUIRED: 'processing required',
  PENDING_CLOSE: 'pending_close',
  CLOSED: 'closed',
  CANCELED: 'canceled',
};

export const statusFilterList = [
  STStatus.NEW,
  STStatus.CONFIRMED,
  STStatus.ACTIVE,
  STStatus.IN_WORK,
  STStatus.PACKED,
  STStatus.DELIVERY,
  STStatus.PROCESSING_REQUIRED,
  STStatus.PENDING_CLOSE,
  STStatus.CLOSED,
  STStatus.CANCELED,
];

export const coffeeStatusList = [
  STStatus.NEW,
  STStatus.CONFIRMED,
  STStatus.ACTIVE,
  STStatus.PACKED,
  STStatus.DELIVERY,
  STStatus.CLOSED,
  STStatus.PROCESSING_REQUIRED,
  STStatus.CANCELED,
];

export const courierStatusList = [
  STStatus.ACTIVE,
  STStatus.PACKED,
  STStatus.DELIVERY,
  STStatus.CLOSED,
  STStatus.PROCESSING_REQUIRED,
];

export const closedStatuses = [STStatus.CLOSED, STStatus.CANCELED];
