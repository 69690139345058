import { makeLoadableList } from '@/store/factory/loadableList';
import { makeSearchModel } from '@/store/factory/search';
import { makeSimpleList } from '@/store/factory/simpleList';
import systemAdmin from './systemAdmin';
import db from './db';
import adminMemberRequestPage from './adminMemberRequestPage';
import adminServices from './adminServices';
import adminStories from './adminStories';
import app from './app';
import auth from './auth';
import booking from './booking';
import gallery from './gallery';
import locale from './locale';
import permissions from './permissions';
import profile from './profile';
import acceptOffers from './acceptOffers';
import rbac from './rbac';
import services from './services';
import snackbar from './snackbar';
import stories from './stories';
import support from './support';
import tags from './tags';
import notificationStats from '@/store/modules/notificationStats';
import articlesByTicket from '@/store/modules/articlesByTicket';
import coffeeBreak from '@/store/modules/coffeeBreak';
import payers from '@/store/modules/payers';
import payments from '@/store/modules/payments';
import adminOrders from '@/store/modules/adminOrders';
import adminProfiles from '@/store/modules/adminProfiles';
import pushStream from '@/store/modules/pushStream';
import adminStock from '@/store/modules/adminStock';
import productionCalendar from '@/store/modules/productionCalendar';
import importantNotificationList from '@/store/modules/importantNotificationList';
import poster from '@/store/modules/poster';
import locationOffers from '@/store/modules/locationOffers';
import timezone from './timezone';
import adminContractsPage from '@/store/modules/adminContractsPage';
import adminEmployees from '@/store/modules/adminEmployees';
import activeContracts from '@/store/modules/activeContracts';
import adminCompaniesPage from '@/store/modules/adminCompaniesPage';
import employees from '@/store/modules/employees';
import { ContractOfferTypes } from '@/constants/offer';
import adminManageLocation from '@/store/modules/adminManageLocation';
import myAccess from '@/store/modules/myAccess';
import serviceSlots from '@/store/modules/serviceSlots';
import profiles from '@/store/modules/profiles';
import adminEvents from '@/store/modules/adminEvents';
import { EventStatus } from '@/constants/event';
import coworkingOccupation from '@/store/modules/coworkingOccupation';
import adminPayers from '@/store/modules/adminPayers';
import appBrand from '@/store/modules/appBrand';
import adminNews from '@/store/modules/adminNews';
import adminPayments from '@/store/modules/adminPayments';
import locationPlan from '@/store/modules/locationPlan';

export default {
  db: db,
  systemAdmin: systemAdmin,
  adminMemberRequestList: makeSimpleList({
    name: 'amr',
    dataModel: 'db/adminMemberRequests',
    params: {
      sort: 'createdAt-',
    },
    withRelations: [
      { field: 'companyId', dataModel: 'db/adminCompanies' },
      { field: 'userId', dataModel: 'db/adminProfiles', idProp: 'userId' },
    ],
  }),
  adminMemberRequestPage,
  adminNotificationJobList: makeSimpleList({
    name: 'main',
    dataModel: 'db/adminNotificationJobs',
  }),
  adminServices,
  adminStories,
  app,
  appBrand,
  auth,
  pushStream: pushStream,
  booking,
  companyList: makeLoadableList({
    name: 'main',
    dataModel: 'db/companies',
    params: {
      status: 'active',
    },
  }),
  peopleList: makeLoadableList({
    name: 'main',
    dataModel: 'db/profiles',
  }),
  companySearch: makeSearchModel({
    name: 'company',
    dataModel: 'db/companies',
  }),
  peopleSearch: makeSearchModel({
    name: 'profile',
    dataModel: 'db/profiles',
  }),
  adminPeopleSearch: makeSearchModel({
    name: 'adminProfile',
    dataModel: 'db/adminProfiles',
  }),
  newsList: makeLoadableList({ name: 'main', dataModel: 'db/news' }),
  upcomingEventList: makeLoadableList({
    name: 'upcoming',
    dataModel: 'db/events',
    params: {
      status: [EventStatus.UPCOMING, EventStatus.CURRENT].join(','),
      sort: 'start_at',
    },
  }),
  pastEventList: makeLoadableList({
    name: 'past',
    dataModel: 'db/events',
    params: {
      status: EventStatus.PASSED,
      sort: 'start_at-',
    },
  }),
  adminNews: adminNews,
  adminEvents: adminEvents,
  adminEventSearch: makeSearchModel({
    name: 'search',
    dataModel: 'db/adminEvents',
  }),
  adminEventTicketSearch: makeSearchModel({
    name: 'search',
    dataModel: 'db/adminEventTickets',
  }),
  gallery,
  locale,
  timezone,
  notificationList: makeSimpleList({
    name: 'main',
    dataModel: 'db/notifications',
  }),
  notificationStats: notificationStats,
  importantNotificationList: importantNotificationList,
  permissions,
  profile,
  acceptOffers,
  locationOffers,
  rbac,
  services,
  serviceSlots: serviceSlots,
  productionCalendar: productionCalendar,
  snackbar,
  stories,
  support,
  tags,
  myOrderList: makeSimpleList({ name: 'main', dataModel: 'db/serviceTickets' }),
  myOrderSearch: makeSearchModel({
    name: 'search',
    dataModel: 'db/serviceTickets',
  }),
  articlesByTicket: articlesByTicket,
  payments: payments,
  coffeeBreak: coffeeBreak,
  poster: poster,
  adminReservations: makeSimpleList({
    name: 'main',
    dataModel: 'db/adminReservations',
  }),
  adminReservationCalendar: makeSimpleList({
    name: 'calendar',
    dataModel: 'db/adminReservations',
    params: {
      status: 'new,invoicing,confirmed,active,closed',
      limit: 1000,
    },
  }),
  adminOrders: adminOrders,
  adminRequests: makeSimpleList({
    name: 'main',
    dataModel: 'db/adminRequests',
  }),
  adminRequestSearch: makeSearchModel({
    name: 'search',
    dataModel: 'db/adminRequests',
  }),
  adminStock: adminStock,
  adminStockTransactions: makeSimpleList({ name: 'main', dataModel: 'db/cbStockTransactions' }),
  profiles: profiles,
  adminProfiles: adminProfiles,
  payers: payers,
  adminPayers: adminPayers,
  adminPayments: adminPayments,
  countries: makeSimpleList({ name: 'list', dataModel: 'db/countries' }),
  cities: makeSimpleList({ name: 'list', dataModel: 'db/cities' }),
  locations: makeSimpleList({ name: 'list', dataModel: 'db/locations' }),
  // TODO: create 1 ref module for all references
  offerTemplates: makeSimpleList({ name: 'list', dataModel: 'db/offerTemplates' }),
  rbacPermissions: makeSimpleList({
    name: 'list',
    dataModel: 'db/rbacPermissions',
    params: {
      sort: 'name',
    },
  }),
  roles: makeSimpleList({ name: 'list', dataModel: 'db/roles' }),
  units: makeSimpleList({
    name: 'ref',
    dataModel: 'db/units',
  }),
  adminContractsPage: adminContractsPage,
  adminContractSearch: makeSearchModel({
    name: 'search',
    dataModel: 'db/offerAcceptances',
    params: {
      offerType: ContractOfferTypes.join(','),
      sort: 'id',
    },
  }),
  adminEmployees: adminEmployees,
  adminCompaniesPage: adminCompaniesPage,
  adminCompanySearch: makeSearchModel({
    name: 'search',
    dataModel: 'db/adminCompanies',
  }),
  activeContracts: activeContracts,
  adminProfilesPage: makeLoadableList({
    name: 'list',
    dataModel: 'db/adminProfiles',
    params: {
      sort: 'fullName',
    },
  }),
  employees: employees,
  adminManageLocation: adminManageLocation,
  adminAccessLogs: makeSimpleList({
    name: 'main',
    dataModel: 'db/adminAccessLogs',
  }),
  myAccess: myAccess,
  adminStaticPages: makeSimpleList({
    name: 'main',
    dataModel: 'db/adminStaticPages',
  }),
  coworkingOccupation: coworkingOccupation,
  locationPlan: locationPlan,
};

// // without autocomplete :\
//
// const modules = {};
// const requireModule = require.context('.', false, /\.js$/);
//
// requireModule.keys().forEach(fileName => {
//   if (fileName === './index.js') {
//     return;
//   }
//
//   const moduleName = fileName.replace(/(\.\/|\.js)/g, '');
//   modules[moduleName] = requireModule(fileName).default;
// });
//
// export default modules;
