<template>
  <Card class="d-flex flex-column" :to="to" v-on="$listeners">
    <AdaptiveImg :src="img">
      <slot name="img"></slot>
    </AdaptiveImg>

    <v-card-title
      class="px-6 pb-0 text-body-1 secondary--text font-weight-medium flex-grow-1 align-content-start"
    >
      <slot>{{ title }}</slot>
    </v-card-title>

    <v-card-title class="px-6 pt-1 text-body-1 primary--text">
      <slot name="time"></slot>
    </v-card-title>

    <v-card-subtitle class="pt-2 px-6 pb-6 text-caption">
      <slot name="status">&nbsp;</slot>
    </v-card-subtitle>

    <slot name="menu" />
  </Card>
</template>

<script>
import Card from '@/components/ui/Card';
import AdaptiveImg from '@/components/ui/AdaptiveImg';

export default {
  name: 'ItemCard',
  components: { AdaptiveImg, Card },
  props: {
    img: String,
    title: String,
    to: Object,
  },
};
</script>
