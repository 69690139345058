export default [
  {
    path: '/admin/static-pages',
    name: 'admin.static-pages',
    component: () =>
      import(/* webpackChunkName: "admin" */ '@/views/admin/static-pages/StaticPageListPage'),
    meta: { isAuth: true },
  },
  {
    path: '/admin/static-pages/new',
    name: 'admin.static-pages.new',
    component: () =>
      import(/* webpackChunkName: "admin" */ '@/views/admin/static-pages/StaticPageFormPage'),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/static-pages/:id(\\d+)/edit',
    name: 'admin.static-pages.edit',
    component: () =>
      import(/* webpackChunkName: "admin" */ '@/views/admin/static-pages/StaticPageFormPage'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
];
