<template>
  <ItemCard :img="item.image" :title="item.name" :to="to">
    <template #time>
      <CoworkingTicketPeriod :ticket="item" />
    </template>
    <template #status>
      {{ $t(`CoworkingTicketStatus.${item.status}`) }}
    </template>
  </ItemCard>
</template>

<script>
import ItemCard from '@/components/calendar/ItemCard';
import CoworkingTicketPeriod from '@/components/coworking/CoworkingTicketPeriod';

export default {
  name: 'CoworkingTicketCard',
  components: { CoworkingTicketPeriod, ItemCard },
  props: {
    item: {
      type: Object,
      required: true,
    },
    to: Object,
  },
};
</script>
