<template>
  <div v-if="events && events.length">
    <div class="d-flex align-baseline justify-space-between">
      <BlockTitle class="font-weight-bold">
        {{ $t('Ближайшие мероприятия') }}
      </BlockTitle>

      <router-link class="text-body-2 t-grey--text" :to="{ name: 'events' }">
        {{ $t('Смотреть все события') }}
      </router-link>
    </div>

    <v-row :dense="$vuetify.breakpoint.xsOnly">
      <v-col v-for="item of screenEvents" :key="item.id" cols="12" md="6" lg="4" xl="3">
        <EventCard
          :to="{ name: 'events.view', params: { id: item.id } }"
          v-bind="item"
          @like="like(item.id)"
          @subscribe="subscribe(item.id)"
          @click:tag="$router.push({ name: 'events', query: { tag: $event } })"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BlockTitle from '@/views/home/calendar/BlockTitle';
import EventCard from '@/components/event/EventCard';

export default {
  name: 'UpcomingEvents',

  components: {
    EventCard,
    BlockTitle,
  },

  props: {
    events: Array,
  },

  computed: {
    screenEvents() {
      return this.events.slice(0, this.$vuetify.breakpoint.lg ? 3 : 4);
    },
  },

  methods: {
    ...mapActions('db/events', ['like', 'subscribe']),
  },
};
</script>
