import http from './http';

const baseUrl = (process.env.VUE_APP_API_OTRS || '').replace(/\/$/g, '');

const otrsApi = {
  commonTicket: {
    list: params => http.get(`${baseUrl}/common/ticket`, { params }),
  },

  calendar: {
    list: async params => {
      const res = await http.get(`${baseUrl}/memo`, { params, useGrid: 0 });
      res.data.forEach(item => {
        item.id = [item.type, item.serviceTicketId, item.eventId].filter(x => x).join('');
      });
      return res;
    },
  },

  ticketArticle: {
    list: ({ ticketId, ...params }) =>
      http.get(`${baseUrl}/ticket/${ticketId}/article`, { params }),
    create: ({ ticketId, ...data }) => http.post(`${baseUrl}/ticket/${ticketId}/article`, data),
    markAsSeen: data => http.patch(`${baseUrl}/article/seen`, data),
  },

  registry: {
    fetchDailyPayment: params => http.get(`${baseUrl}/registry/daily-payment`, { params }),
    fetchMonthlyBill: params => http.get(`${baseUrl}/registry/monthly-bill`, { params }),
    fetchDailyPoster: params => http.get(`${baseUrl}/registry/daily-poster`, { params }),
  },

  stocks: {
    list: params => http.get(`${baseUrl}/stock`, { params }),
    find: ({ id }) => http.get(`${baseUrl}/stock/${id}`),
    create: data => http.post(`${baseUrl}/stock`, data),
    update: ({ id, ...data }) => http.patch(`${baseUrl}/stock/${id}`, data),
    delete: ({ id }) => http.delete(`${baseUrl}/stock/${id}`),
  },

  admin: {
    request: {
      list: params => http.get(`${baseUrl}/servicing/admin/request`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/servicing/admin/request/${id}`),
    },

    updateRoleServices: ({ id, services }) =>
      http.put(`${baseUrl}/servicing/admin/role/${id}/service`, { services }),

    serviceResponsible: {
      list: ({ type, ...params }) =>
        http.get(`${baseUrl}/servicing/admin/servicetype/${type}/admins`, { params }),
    },

    accountants: {
      list: params => http.get(`${baseUrl}/servicing/admin/accountants`, { params }),
    },

    serviceTicket: {
      list: params => http.get(`${baseUrl}/servicing/admin/service-ticket`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/servicing/admin/service-ticket/${id}`),
      paymentRefunded({ id }) {
        return http.patch(`${baseUrl}/servicing/service-ticket/${id}/payment-refunded`);
      },
      updateState: ({ id, ...data }) =>
        http.patch(`${baseUrl}/servicing/admin/service-ticket/${id}/state`, data),
      updateResponsible: ({ id, ...data }) =>
        http.patch(`${baseUrl}/servicing/admin/service-ticket/${id}/responsible`, data),
      updateDeadline: ({ id, ...data }) =>
        http.patch(`${baseUrl}/servicing/admin/service-ticket/${id}/deadline`, data),
      extendExpirationDate: ({ id, ...data }) =>
        http.patch(
          `${baseUrl}/servicing/admin/service-ticket/${id}/extend-expiration-period`,
          data
        ),
      createFromContract: data =>
        http.post(`${baseUrl}/servicing/admin/service-ticket-from-contract`, data),
    },
  },
};

export default otrsApi;
