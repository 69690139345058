import HomePage from '@/views/home/HomePage';
import HomeTabs from '@/views/home/HomeTabs';
import CalendarPage from '@/views/home/calendar/CalendarPage';

export default [
  {
    path: '/home',
    name: 'home',
    redirect: { name: 'home.calendar' },
    components: {
      default: HomePage,
      tabs: HomeTabs,
    },
    meta: { isAuth: true },
    children: [
      {
        path: 'calendar',
        name: 'home.calendar',
        component: CalendarPage,
        meta: { isAuth: true },
      },
      {
        path: 'orders',
        name: 'home.orders',
        components: {
          default: () =>
            import(/* webpackChunkName: "home-orders" */ '@/views/home/orders/OrdersPage'),
          search: () =>
            import(/* webpackChunkName: "home-orders" */ '@/views/home/orders/OrderSearch'),
        },
        meta: { isAuth: true },
      },
      {
        path: 'tickets',
        name: 'home.tickets',
        components: {
          default: () =>
            import(/* webpackChunkName: "home-tickets" */ '@/views/home/tickets/TicketsPage'),
          search: () =>
            import(/* webpackChunkName: "home-tickets" */ '@/views/home/tickets/TicketSearch'),
        },
        meta: { isAuth: true },
      },
      {
        path: 'notifications',
        name: 'home.notifications',
        component: () =>
          import(
            /* webpackChunkName: "home-notifications" */ '@/views/home/notifications/NotificationList'
          ),
        meta: { isAuth: true },
      },
    ],
  },
  {
    path: '/home/orders/:id(\\d+)',
    name: 'home.orders.view',
    component: () =>
      import(/* webpackChunkName: "home-orders" */ '@/views/home/orders/OrderView.vue'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/home/orders/:serviceTicketId(\\d+)/coworking-ticket/:id(\\d+)',
    name: 'home.orders.coworking-ticket.view',
    component: () =>
      import(
        /* webpackChunkName: "home-orders" */ '@/views/home/tickets/CoworkingTicketViewPage.vue'
      ),
    props: route => ({
      serviceTicketId: parseInt(route.params.serviceTicketId),
      id: parseInt(route.params.id),
    }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/home/orders/:serviceTicketId(\\d+)/hotel-ticket/:id(\\d+)',
    name: 'home.orders.hotel-ticket.view',
    component: () =>
      import(/* webpackChunkName: "home-orders" */ '@/views/home/tickets/HotelTicketViewPage.vue'),
    props: route => ({
      serviceTicketId: parseInt(route.params.serviceTicketId),
      id: parseInt(route.params.id),
    }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/home/coworking-tickets/:id(\\d+)',
    name: 'home.coworking-tickets.view',
    component: () =>
      import(
        /* webpackChunkName: "home-tickets" */ '@/views/home/tickets/CoworkingTicketViewPage.vue'
      ),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/home/tickets/coworking/:id(\\d+)',
    name: 'home.tickets.coworking.view',
    component: () =>
      import(
        /* webpackChunkName: "home-tickets" */ '@/views/home/tickets/CoworkingTicketViewPage.vue'
      ),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/home/tickets/hotel/:id(\\d+)',
    name: 'home.tickets.hotel.view',
    component: () =>
      import(/* webpackChunkName: "home-tickets" */ '@/views/home/tickets/HotelTicketViewPage.vue'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/home/orders/:id(\\d+)/payment',
    name: 'home.orders.payment',
    component: () =>
      import(/* webpackChunkName: "home-orders" */ '@/views/home/orders/OrderPaymentPage'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
];
