export const DeliveryType = {
  COURIER: 'courier',
  PICKUP: 'pickup',
  PICKUP_AND_COURIER: 'pickup_and_courier',
  SELF_PICKUP: 'self_pickup',
};

export const ServiceDialog = {
  BOOKING_ROOM: 'booking.room',
  CONTACTING_TPARK: 'contacting_tpark',
  COFFEE_BREAK: 'coffee_break',
  POSTER: 'poster',
  MASSAGE: 'massage',
};

export const ServiceType = {
  BOOKING: 'booking',
  COMMERCIAL: 'commercial',
  CONTACTING_TPARK: 'contacting_tpark',
  COFFEE_BREAK: 'coffee_break',
  POSTER: 'poster',
  MASSAGE: 'massage',
  EVENT_TICKET: 'event_ticket',
  COWORKING: 'coworking',
  HOTEL: 'hotel',
  RENT_OFFICE: 'rent_office',
};

export const BookingServiceTypes = [ServiceType.BOOKING, ServiceType.MASSAGE];

export const CoffeeServiceTypes = [ServiceType.COFFEE_BREAK, ServiceType.POSTER];

export const PaidServiceTypes = [
  ServiceType.BOOKING,
  ServiceType.MASSAGE,
  ServiceType.COFFEE_BREAK,
  ServiceType.POSTER,
  ServiceType.COWORKING,
  ServiceType.HOTEL,
  ServiceType.EVENT_TICKET,
];
