<template>
  <div>
    <BlockTitle primary>{{ $t('Текущие события') }}</BlockTitle>
    <v-row class="px-sm-6">
      <v-col class="d-flex">
        <div>
          <img :src="require('@/assets/empty/calendar.png')" width="42" height="48" alt="" />
        </div>
        <div class="pl-4 t-grey--text">
          {{ $t('Здесь будут собраны события...') }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BlockTitle from '@/views/home/calendar/BlockTitle';

export default {
  name: 'EmptyMessage',
  components: { BlockTitle },
};
</script>
