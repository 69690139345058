<template>
  <div v-if="services && services.length">
    <div class="d-flex align-baseline justify-space-between">
      <BlockTitle class="font-weight-bold">
        {{ $t('Партнёрские предложения') }}
      </BlockTitle>

      <router-link
        v-if="category"
        class="text-body-2 t-grey--text"
        :to="{ name: 'services.category', params: { id: category.id } }"
      >
        {{ $t('Посмотрите все предложения партнёров') }}
      </router-link>
    </div>

    <v-row :dense="$vuetify.breakpoint.xsOnly">
      <v-col v-for="item of services" :key="item.id" cols="6" md="3">
        <ServiceCard :service="item" hide-favorite />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BlockTitle from '@/views/home/calendar/BlockTitle';
import ServiceCard from '@/views/services/card/ServiceCard';

export default {
  name: 'PartnerOffers',
  components: { ServiceCard, BlockTitle },
  props: {
    category: Object,
    services: Array,
  },
};
</script>
