<template>
  <Card v-bind="$attrs">
    <AdaptiveImg :src="image" :class="[grayscale && 'img-gray']">
      <slot name="img-content" />
    </AdaptiveImg>

    <div class="px-6 py-4 mb-n2">
      <v-card-title class="pa-0 mb-2 text-body-1 font-weight-medium secondary--text">
        <div class="flex-grow-1 d-flex justify-space-between align-start">
          {{ title }}
          <FavoriteBtn
            v-if="!hideFavorite"
            :service-id="serviceId"
            small
            size="16"
            @mousedown.prevent.stop="() => {}"
          />
        </div>
      </v-card-title>

      <v-card-text class="pa-0 text-body-2">
        <div v-if="$slots.notice" class="mb-2 primary--text">
          <slot name="notice" />
        </div>
        <div v-if="subtitle" class="mb-2">
          {{ subtitle }}
        </div>
      </v-card-text>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/ui/Card';
import AdaptiveImg from '@/components/ui/AdaptiveImg';
import FavoriteBtn from '@/views/services/card/FavoriteBtn';

export default {
  name: 'ServiceBasicCard',
  components: { AdaptiveImg, FavoriteBtn, Card },
  props: {
    serviceId: Number,
    favorite: Boolean,
    grayscale: Boolean,
    hideFavorite: {
      type: Boolean,
      default: false,
    },
    image: String,
    subtitle: String,
    title: String,
  },
};
</script>

<style scoped>
.img-gray::v-deep .v-image__image {
  filter: grayscale(100%);
}
</style>
