import i18n from '@/plugins/i18n';
import IconOlvery from '@/components/icons/IconOlvery.vue';
import CoffeeBreak from '@/components/layout/menu/item/CoffeeBreak.vue';
import CompaniesAndPeople from '@/components/layout/menu/item/CompaniesAndPeople.vue';

export default [
  // user
  {
    title: i18n.t('nav.user.home'),
    route: { name: 'home' },
    icon: IconOlvery,
    guard: 'profile:my-profile.read',
  },
  {
    title: i18n.t('nav.user.news'),
    route: { name: 'news' },
    icon: 'mdi-newspaper-variant-outline',
    guard: 'event:news.search',
  },
  {
    title: i18n.t('nav.user.events'),
    route: { name: 'events' },
    icon: 'mdi-calendar-outline',
    guard: 'event:event.search',
  },
  {
    title: i18n.t('nav.user.services'),
    route: { name: 'services.all' },
    icon: 'mdi-star-outline',
    guard: 'otrs:category.list',
  },
  {
    title: i18n.t('nav.user.community'),
    route: { name: 'community' },
    icon: 'mdi-account-multiple-outline',
    guard: ['profile:company.list', 'profile:profile.list'],
  },
  // admin
  {
    title: i18n.t('nav.admin.booking'),
    route: { name: 'admin.booking' },
    icon: 'mdi-calendar-clock',
    guard: [
      'otrs:conference.reservation.calendar',
      'otrs:coworking.admin.ticket.calendar',
      'otrs:hotel.admin.ticket.calendar',
      'otrs:conference.reservation.list',
      'otrs:coworking.admin.ticket.list',
      'otrs:hotel.admin.ticket.list',
    ],
  },
  {
    title: i18n.t('nav.admin.store'),
    route: { name: 'admin.coffee-break' },
    icon: 'mdi-coffee-outline',
    guard: ['otrs:coffee-break.admin.order.list', 'otrs:servicing.service-ticket-delivery.list'],
    component: CoffeeBreak,
  },
  {
    title: i18n.t('nav.admin.requests'),
    route: { name: 'admin.requests' },
    icon: 'mdi-forum-outline',
    guard: 'otrs:admin.request.list',
  },
  {
    title: i18n.t('nav.admin.media'),
    route: { name: 'admin.media' },
    icon: 'mdi-newspaper-variant-outline',
    guard: ['event:admin.news.search', 'event:admin.event.update'],
  },
  {
    title: i18n.t('nav.admin.cap'),
    route: { name: 'admin.cap' },
    icon: 'mdi-folder-account-outline',
    guard: [
      'profile:admin.company.patch',
      'profile:admin.profile.create',
      'profile:admin.member-request.list',
      'offer:offer.create',
    ],
    component: CompaniesAndPeople,
  },
  {
    title: i18n.t('nav.admin.services'),
    route: { name: 'admin.services' },
    icon: 'mdi-shape-outline',
    guard: 'otrs:service.create',
  },
  {
    title: i18n.t('nav.admin.ads'),
    route: { name: 'admin.ads' },
    icon: 'mdi-bullhorn-outline',
    guard: ['profile:admin.notification-job.search', 'otrs:promo.story.create'],
  },
  {
    title: i18n.t('nav.admin.acs'),
    route: { name: 'admin.acs' },
    icon: 'mdi-lock-open-outline',
    guard: 'nfc:access-log.list',
  },
  {
    title: i18n.t('Справочники'),
    icon: 'mdi-book-open-outline',
    route: { name: 'admin.ref' },
    guard: [
      'doc:template.update',
      'rbac:admin.role.update',
      'location:price-category.update',
      'location:counter.update',
      'event:admin.news-category.search',
      'event:admin.category.search',
      'acs:admin.access-level.list',
      'otrs:coworking.admin.work-place.list',
      'otrs:hotel.admin.room.list',
      'otrs:coffee-break.admin.product.list',
      'otrs:stock.create',
    ],
  },
  {
    title: i18n.t('Приборы учета'),
    icon: 'mdi-counter',
    route: { name: 'admin.meter-readings' },
    guard: 'location:meter-reading.list',
  },
  {
    title: i18n.t('nav.admin.registry'),
    icon: 'mdi-calculator-variant-outline',
    route: { name: 'admin.registry' },
    guard: [
      'otrs:registry.daily-payment.search',
      'otrs:registry.monthly-bill.search',
      'otrs:registry.daily-poster.search',
      'billing:accountant.payment.list',
      'doc:document.search',
    ],
  },
  {
    title: i18n.t('nav.admin.staticPages'),
    icon: 'mdi-book-open-page-variant-outline',
    route: { name: 'admin.static-pages' },
    guard: 'auth:admin.static-page.list',
  },
  // operator
  {
    title: i18n.t('nav.operator.locations'),
    route: { name: 'operator.locations.layout' },
    icon: 'mdi-map-marker-outline',
    guard: 'auth:admin.location.list',
  },
  {
    title: i18n.t('nav.operator.access'),
    route: { name: 'operator.access' },
    icon: 'mdi-shield-lock-outline',
    guard: ['rbac:role.update', 'rbac:permission.update', 'offer:offer-template.update'],
  },
  {
    title: i18n.t('nav.operator.microservices'),
    route: { name: 'rbac.microservices' },
    icon: 'mdi-docker',
    guard: 'rbac:micro-service.update',
  },
];
