<template>
  <ItemCard :img="item.image" :title="item.name" :to="to">
    <template #time>
      {{ item.startAt | date('dateLong') }}, {{ item.startAt | time }} -
      {{ item.endAt | timeTo }}
      <Tag
        v-if="item.status === EventStatus.CURRENT"
        class="ml-2"
        color="primary"
        text-color="white"
        x-small
      >
        {{ $t('Сейчас') }}
      </Tag>
      <Tag
        v-if="item.status === EventStatus.CANCELED"
        class="ml-2"
        color="t-grey"
        text-color="white"
        x-small
      >
        {{ $t('Отменено') }}
      </Tag>
    </template>

    <template v-if="statusText" #status>
      {{ statusText }}
    </template>

    <template #menu>
      <CardMenu v-if="item.status === EventStatus.CANCELED && item.subscribed">
        <v-list>
          <v-list-item @click="onHide">
            {{ $t('Скрыть') }}
          </v-list-item>
        </v-list>
      </CardMenu>
    </template>
  </ItemCard>
</template>

<script>
import { mapActions } from 'vuex';
import { EventStatus, EventTicketStatus } from '@/constants/event';
import ItemCard from '@/components/calendar/ItemCard';
import Tag from '@/components/ui/Tag';
import CardMenu from '@/components/ui/CardMenu';

export default {
  name: 'EventCard',

  components: { CardMenu, Tag, ItemCard },

  props: {
    item: {
      type: Object,
      required: true,
    },
    to: Object,
  },

  computed: {
    statusText() {
      if ([EventStatus.UPCOMING, EventStatus.CURRENT].includes(this.item.status)) {
        if (this.item.eventTicket && this.item.eventTicket.status !== EventTicketStatus.CANCELED) {
          return this.$t('Участие подтверждено');
        }

        if (this.item.registrationType === 'internal') {
          return this.$t('Регистрация открыта');
        }
      }

      return null;
    },
  },

  beforeCreate() {
    this.EventStatus = EventStatus;
  },

  methods: {
    ...mapActions('db/events', ['unsubscribe']),
    ...mapActions('db/calendar', { fetchCalendarItems: 'fetchItems' }),

    async onHide() {
      await this.unsubscribe({ eventId: this.item.eventId });
      await this.fetchCalendarItems();
    },
  },
};
</script>
