<template>
  <div>
    <BlockTitle :primary="isPrimary">
      {{ title }}
    </BlockTitle>
    <v-row>
      <v-col v-for="(item, idx) of items" :key="idx" cols="12" sm="6" md="4" lg="3">
        <ReservationCard
          v-if="item.type === 'booking'"
          :item="item"
          :to="{ name: 'home.orders.view', params: { id: item.serviceTicketId } }"
        />
        <ReservationCard v-if="item.type === 'shared_booking'" :item="item" shared />
        <EventCard
          v-if="item.type === 'user-event'"
          :item="item"
          :to="{ name: 'events.view', params: { id: item.eventId } }"
        />
        <CoworkingTicketCard
          v-if="item.type === 'coworking'"
          :item="item"
          :to="{ name: 'home.tickets.coworking.view', params: { id: item.coworkingTicketId } }"
        />
        <CoworkingTicketCard
          v-if="item.type === 'hotel'"
          :item="item"
          :to="{ name: 'home.tickets.hotel.view', params: { id: item.hotelTicketId } }"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BlockTitle from '@/views/home/calendar/BlockTitle.vue';
import ReservationCard from '@/components/calendar/ReservationCard';
import EventCard from '@/components/calendar/EventCard';
import CoworkingTicketCard from '@/components/calendar/CoworkingTicketCard';

export default {
  name: 'InlineItems',

  components: {
    CoworkingTicketCard,
    EventCard,
    ReservationCard,
    BlockTitle,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    isPrimary: Boolean,
    title: String,
  },
};
</script>
