<template>
  <ItemCard :img="item.image" :title="item.name" :to="to">
    <template #time>
      {{ item.startAt | date('dateLong') }}, {{ item.startAt | time }} -
      {{ item.endAt | timeTo }}
    </template>
    <template v-if="shared" #img>
      <div class="pa-4 d-flex justify-end">
        <Tag text-color="primary" x-small>
          {{ $t('Доступ в помещение') }}
        </Tag>
      </div>
    </template>
    <template #status>
      {{ $t(`ServiceTicketStatus.${item.status}`) }}
    </template>
  </ItemCard>
</template>

<script>
import ItemCard from '@/components/calendar/ItemCard';
import Tag from '@/components/ui/Tag.vue';

export default {
  name: 'ReservationCard',
  components: { Tag, ItemCard },
  props: {
    item: {
      type: Object,
      required: true,
    },
    to: Object,
    shared: Boolean,
  },
};
</script>
